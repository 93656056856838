<template>
    <div id="PublicThanksView">
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                    <template #menu>
                        <slot name="menu" />
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-row>
                    <v-col cols="12">
                        <p>Thank you, as soon as your payment is validated you should receive an email confirmation</p>
                    </v-col>
                </v-row>
            </template>
        </view-layout>
    </div>
</template>

<script>
//TODO check invoice status?
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';
export default {
    components: {
        ViewLayout,
        PageHeader
    }
};
</script>
